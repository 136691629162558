import { createContext, useContext, useMemo } from 'react';
import {
  ItemSource,
  ItemSourceValue,
  useLocallyOverridableKeyValueStorage,
} from '@lib/hooks/use-locally-overridable-key-value-store';

import { AppSettingsIdType } from '@constants/app-settings-ids';

export type AppSetting = {
  name: string;
  value: string;
};

export const AppSettingsContext = createContext<Array<AppSetting>>([]);

export const useAppSettings = () => {
  const strapiFeatures = useContext(AppSettingsContext);

  const { clearValue, getValue, setValue } = useLocallyOverridableKeyValueStorage<string>({
    initialItems: useMemo(
      () =>
        strapiFeatures.map(f => ({
          key: f.name,
          value: f.value,
        })),
      [strapiFeatures]
    ),
    valueDecoders: useMemo(
      () => ({
        [ItemSource.URL]: value => value,
        [ItemSource.Cookies]: value => value,
        [ItemSource.API]: (value: string) => value,
      }),
      []
    ),
    valueEncoders: useMemo(
      () => ({
        [ItemSource.Cookies]: (value: string) => value.toString(),
      }),
      []
    ),
    storePrefix: 'fe_setting',
  });

  return {
    getSettingValue: (name: AppSettingsIdType) => {
      return getValue(name).value;
    },
    getSetting: (name: AppSettingsIdType) => {
      return getValue(name);
    },
    setSettingValue: (name: AppSettingsIdType, value: string, source: 'URL' | 'Cookies') => {
      setValue(name, value, source);
    },
    clearValue: (name: AppSettingsIdType, source: ItemSourceValue) => {
      clearValue(name, source);
    },
  };
};
