import React, { createContext, useCallback, useMemo } from 'react';
import { RootState, useDispatch, useSelector } from '@store/configureStore';

import { RoboModel } from '@lib/robo/robo-model';
import { roboClientManager } from '@lib/robo/robo-client-manager';
import { RoboClient } from '@lib/robo/robo-client';

import { ModelStore, useModelStore } from '@webapp/hooks/use-model-store';

import { initiateBluetoothConnection, connect, disconnect, RoboState } from '@webapp/store/slices/devices/robo.slice';
import { FetchedRobo } from '@webapp/store/slices/devices/bridge.slice';
import { ModuleState } from '@webapp/store/types';
import { useAppSettings } from '@lib/hooks/use-app-settings';
import { AppSettingsIds } from '@constants/app-settings-ids';
import { ModuleId, ModulesCollectionTypes, ModuleTypes } from '@lib/robo/types';
import { selectConnectedModuleIdsFromModel } from '@webapp/store/slices/model/model.selectors';

type RoboContextType = RoboState & {
  macAddress: string | null;
  connect: ({ id, alias }: { id: string; alias: string }) => void;
  disconnect: () => void;
  initiateBluetoothConnection: () => void;
  client: RoboClient | null;
  model: RoboModel | null;
  store: ModelStore | null;
  connectedModules: ConnectedModule[];
};

export type ConnectedModule = {
  collectionType: ModulesCollectionTypes;
  type: ModuleTypes;
  id: ModuleId;
  index: number;
};

export const RoboContext = createContext<RoboContextType>({
  id: '',
  name: '',
  alias: '',
  displayAlias: '',
  connected: false,
  batchCheckTimeout: null,
  transport: '',
  macAddress: null,
  connect: () => undefined,
  disconnect: () => undefined,
  initiateBluetoothConnection: () => undefined,
  client: null,
  model: null,
  store: null,
  connectedModules: [],
});

/**
 * RoboProvider component
 *
 * This component provides a context for managing the Robo device state and interactions.
 * It wraps the application and makes Robo-related functionality available to child components.
 *
 * The provider manages:
 * - Connection and disconnection to/from the Robo device
 * - Access to the RoboClient and RoboModel instances
 * - Access to the connected modules information
 * - Bluetooth connection initiation
 *
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The child components to be wrapped by the provider
 */

export const RoboProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const robo = useSelector((state: RootState) => state.webapp.devices.robo);
  const bridge = useSelector((state: RootState) => state.webapp.devices.bridge);
  const model = useSelector((state: RootState) => state.webapp.model as ModuleState);

  const { getSettingValue } = useAppSettings();

  const roboFromBridge = bridge.roboList.find((r: FetchedRobo) => r.name === robo.name);

  const modelStore = useModelStore();

  const roboClient = useMemo(() => {
    return roboClientManager.getClient(robo.id);
  }, [robo.id]);

  const roboModel = useMemo(() => {
    if (roboClient) {
      return roboClient.getModel(modelStore.methods);
    }

    return null;
  }, [roboClient, modelStore.methods]);

  const batchCheckTimeoutRaw = getSettingValue(AppSettingsIds.BatchSensorsCheckTimeout);

  const connectedModuleIds = selectConnectedModuleIdsFromModel(model);

  const connectedModules = useMemo<ConnectedModule[]>(() => {
    return connectedModuleIds.map(moduleId => {
      const { collectionType, type, position } = RoboModel.parseModuleId(moduleId);

      return {
        id: moduleId,
        collectionType,
        type,
        index: position,
      };
    });
  }, [connectedModuleIds]);

  /**
   * Initiates a Bluetooth connection to the Robo device.
   */
  const initiateRoboBluetoothConnection = useCallback(() => {
    const timeout = typeof batchCheckTimeoutRaw === 'string' ? parseInt(batchCheckTimeoutRaw, 10) : 0;
    const batchCheckTimeout = Math.max(timeout, 300);

    dispatch(initiateBluetoothConnection({ batchCheckTimeout }));
  }, [dispatch, batchCheckTimeoutRaw]);

  /**
   * Connects to a Robo device.
   *
   * @param {Object} options - The options object.
   * @param {string} options.id - The ID of the Robo device to connect to.
   * @param {string} options.alias - The alias of the Robo device to connect to.
   */
  const connectRobo = useCallback(
    ({ id, alias }: { id: string; alias: string }) => {
      const timeout = typeof batchCheckTimeoutRaw === 'string' ? parseInt(batchCheckTimeoutRaw, 10) : NaN;

      dispatch(
        connect({
          id,
          alias,
          batchCheckTimeout: isNaN(timeout) ? null : timeout,
          transport: 'mqtt',
        })
      );
    },
    [dispatch]
  );

  /**
   * Disconnects from the currently connected Robo device.
   */
  const disconnectRobo = useCallback(() => dispatch(disconnect()), [dispatch]);

  return (
    <RoboContext.Provider
      value={{
        ...robo,
        macAddress: roboFromBridge?.macAddress ?? null,
        initiateBluetoothConnection: initiateRoboBluetoothConnection,
        connect: connectRobo,
        disconnect: disconnectRobo,
        client: roboClient,
        model: roboModel,
        store: modelStore,
        connectedModules,
      }}
    >
      {children}
    </RoboContext.Provider>
  );
};
