import { MQTTClient, MQTTMessage } from '@lib/mqtt/mqtt-client';
import { TransportInterface, MQTTTransportOptions, TransportMessage } from './types';

/**
 * Implements MQTT transport functionality for Robo devices using MQTT protocol
 * Handles bidirectional communication through transmit and receive topics
 */
export class MQTTTransport implements TransportInterface {
  private client: MQTTClient;
  private transmitTopic = '';
  private receiveTopic = '';
  private dataListener?: (message: TransportMessage) => void;

  /**
   * Creates a new MQTTTransport instance
   * @param client - The MQTT client instance to use for communication
   */
  constructor(client: MQTTClient) {
    this.client = client;
  }

  /**
   * Establishes MQTT connection and sets up topic subscriptions
   * @param options - MQTT transport options containing roboIdentifier and optional topic names
   * @param options.roboIdentifier - Unique identifier for the Robo device
   * @param options.transmitTopic - Optional custom topic for receiving messages from Robo
   * @param options.receiveTopic - Optional custom topic for sending messages to Robo
   */
  async connect(options: MQTTTransportOptions): Promise<void> {
    // Use provided topics or generate default ones based on roboIdentifier
    this.transmitTopic = options.transmitTopic ?? `robo/${options.roboIdentifier}/transmit`;
    this.receiveTopic = options.receiveTopic ?? `robo/${options.roboIdentifier}/receive`;

    this.client.subscribe(this.transmitTopic);
    this.client.onMessageArrived(this.onMessageReceived.bind(this));
  }

  /**
   * Disconnects from MQTT broker by unsubscribing from the transmit topic
   */
  async disconnect(): Promise<void> {
    if (this.transmitTopic) {
      this.client.unsubscribe(this.transmitTopic);
    }
  }

  /**
   * Sends data to the Robo device through the receive topic
   * @param data - Data to send, either as Uint8Array or string
   * @throws Error if transport is not connected
   */
  send(data: Uint8Array | string): void {
    if (!this.receiveTopic) {
      throw new Error('Transport not connected - call connect() first');
    }
    this.client.publish(this.receiveTopic, data);
  }

  /**
   * Sets up a listener for incoming messages from the Robo device
   * @param listener - Callback function to handle received messages
   */
  onData(listener: (message: TransportMessage) => void) {
    this.dataListener = listener;
  }

  /**
   * Waits for a specific message that matches the given predicate
   * @param predicate - Function to test incoming messages
   * @param timeout - Optional timeout in milliseconds
   * @returns Promise that resolves when a matching message is received
   * @throws Error if timeout occurs before receiving matching message
   */
  waitForSpecificMessage<T>(
    predicate: (message: TransportMessage, resolve: (value: T) => void) => void,
    timeout?: number
  ): Promise<T> {
    return this.client.waitForSpecificMessage<T>((mqttMessage: MQTTMessage, resolve: (value: T) => void) => {
      const transportMessage: TransportMessage = {
        data: mqttMessage.payloadBytes,
        topic: mqttMessage.destinationName,
      };
      predicate(transportMessage, resolve);
    }, timeout);
  }

  /**
   * Handles incoming MQTT messages and forwards them to the data listener if topic matches
   * @param _mqttClient - MQTT client instance (unused)
   * @param message - Received MQTT message
   * @private
   */
  private onMessageReceived(_mqttClient: MQTTClient, message: MQTTMessage) {
    if (message.destinationName === this.transmitTopic && this.dataListener) {
      const transportMessage: TransportMessage = {
        data: message.payloadBytes,
        topic: message.destinationName,
      };
      this.dataListener(transportMessage);
    }
  }
}
