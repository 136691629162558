import { TransportInterface, TransportMessage } from './transport/types';

import Command from '@lib/robo/types/commands';
import { HandlerType } from '@lib/robo/types/handlers';

import { RoboModel } from '@lib/robo/robo-model';

import { BatchSensorsFetcher } from '@lib/robo/batch-sensors-fetcher';
import { concatUint8Arrays, convertUnsignedNumberToBytes, ensureTypedArray, isAsciiString } from '@lib/utils/hex';
import { FileChunk } from '@lib/utils/file';
import CancelToken from '@lib/utils/cancel-token';
import { CommandsQueue } from '@lib/robo/commands-queue';
import Commands from '@lib/robo/types/commands';
import { type ModuleId, ModulesCollectionTypes } from './types';

import { compareSemanticVersions } from '@lib/utils';
import { FIRMWARE_MAIN_BLOCK_MULTIPLE_COMMANDS_SUPPORTS_FROM } from '@webapp/config/constants';

import { type TransactionCommandsQueue } from './transactions/types';
import { Transaction } from './transactions/transaction';
import { TransactionsManager, DEFAULT_TRANSACTION_NAME } from './transactions/transactions-manager';

import { BluetoothTransport } from './transport/bluetooth-transport';

const defaultConfig = new Uint8Array([0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0, 0, 0, 0, 0, 0, 0]);

export const ROBO_NAME_MAX_CHARACTERS_COUNT = 16;
export const ROBO_FIRMWARE_MQTT_MAX_CHUNK_SIZE = 8192;

// todo: Implement firmware chunking for Bluetooth
export const ROBO_FIRMWARE_BLUETOOTH_MAX_CHUNK_SIZE = 512;
export const ROBO_MQTT_MAX_COMMAND_SIZE = 8192;
export const ROBO_BLUETOOTH_MAX_COMMAND_SIZE = 512; // BLE typical max MTU size

export const DEFAULT_COMMAND_QUEUE_INERVAL = 40;

export type FirmwareVersion = {
  major: string;
  minor: string;
  build: string;
};

export type RoboClientOptions = {
  batchCheckTimeout?: number;
  profileMessagesFrequency?: boolean;
  profileMessagesFrequencyWindow?: number;
};

export type ResponseHandlerFunction = (payload: any | { isError: boolean; targetId: string }) => void;

/**
 * RoboClient handles communication with a robo over MQTT.
 * It manages:
 * - MQTT message sending/receiving on robo-specific topics
 * - Command queuing and transactions for batched commands
 * - Robo state tracking (battery, firmware version, etc)
 * - Event handlers for robo responses
 * - Sensor data polling
 * - Robo model/configuration
 *
 * The client supports both immediate command sending and queued/transactional
 * command execution. It can handle single commands or batched multiple commands
 * depending on firmware support.
 */
export class RoboClient {
  roboIdentifier: string;
  id: string;
  name: string;

  private transport: TransportInterface;

  model?: RoboModel;

  config = defaultConfig;
  batteryCapacity = 0;
  batteryMode = '';
  loudness = 0;
  version?: number;
  firmwareVersion?: FirmwareVersion;

  broadcastHandlers: Record<string, Record<string, ResponseHandlerFunction>> = {};
  targetHandlers: Record<string, Record<string, ResponseHandlerFunction>> = {};

  batchSensorsFetcher: BatchSensorsFetcher;

  profileMessagesFrequency: boolean;
  profileMessagesFrequencyWindow: number;
  profileMessagesFrequencyIntervalId: ReturnType<typeof setTimeout> | null = null;
  currentMessagesFrequency: number | null;
  messagesTimestamps: Array<number>;
  commandsQueue: CommandsQueue;
  transactionsManager: TransactionsManager;

  /**
   * Creates a new RoboClient instance with the given MQTT client and robot identifier.
   * @constructor
   * @param {TransportInterface} transport - The Robo transport object.
   * @param {string} roboIdentifier - The identifier of the robot to connect to.
   * @param {Object} options - The options to use for the RoboClient instance.
   */
  constructor(transport: TransportInterface, roboIdentifier: string, options: RoboClientOptions = {}) {
    this.transport = transport;
    this.roboIdentifier = roboIdentifier;
    this.id = roboIdentifier;
    this.name = roboIdentifier;

    // Define topics specific to this robot.
    this.transport.onData(this.onMessageReceivedHandler.bind(this));

    this.batchSensorsFetcher = new BatchSensorsFetcher(this, {
      checkTimeout: options.batchCheckTimeout ?? null,
    });

    this.profileMessagesFrequency = options.profileMessagesFrequency ?? false;
    this.profileMessagesFrequencyWindow = options.profileMessagesFrequencyWindow ?? 1000;
    this.messagesTimestamps = [];
    this.currentMessagesFrequency = null;

    if (options.profileMessagesFrequency) {
      this.startProfileMessagesFrequency();
    }

    // todo Revise the commands queue. We can use the queue from transaction manager but instead of sending all commands in batch we can send them one by one
    this.commandsQueue = new CommandsQueue({
      sendCommandImmediately: this.sendCommandImmediately.bind(this),
      config: {
        commands: {
          [Commands.CMD_RUN_STOP]: {
            interval: 0,
          },
          // timeout for CMD_DISPLAY_LOAD_ANIMATION is 0 because we need to load
          //all frames before starting animation with CMD_DISPLAY_ANIMATION
          [Commands.CMD_DISPLAY_LOAD_ANIMATION]: {
            interval: 0,
          },
        },
        defaultInterval: DEFAULT_COMMAND_QUEUE_INERVAL,
      },
    });

    this.transactionsManager = new TransactionsManager(this);

    this.transport.connect({ roboIdentifier });
  }

  /**
   * Sends a message to the robo on the receive topic.
   * @param {string | Uint8Array} message - The message to send to the robo.
   */
  send(message: string | Uint8Array) {
    if (this.profileMessagesFrequency) {
      this.messagesTimestamps.push(Date.now());
    }

    this.transport.send(message);
  }

  /**
   * Unsubscribes from the transmit topic to stop receiving responses from the robo.
   */
  disconnect() {
    this.stopBatchSensorsCheck();

    if (this.profileMessagesFrequencyIntervalId) {
      clearInterval(this.profileMessagesFrequencyIntervalId);
      this.profileMessagesFrequencyIntervalId = null;
    }

    this.transport.disconnect();
  }

  /**
   * Returns the RoboModel instance, creating it if necessary.
   * @param {Object} modelStore - The store object.
   * @returns {RoboModel} The RoboModel instance.
   */
  getModel(modelStore = null) {
    if (!this.model) {
      this.model = new RoboModel(this, modelStore ?? undefined);

      this.requestRoboConfiguration();
      this.requestBattery();
      this.requestFirmwareVersion();
      this.requestLoudness();
    }

    return this.model;
  }

  /**
   * Generates a random ID to be used as a handler ID.
   * @returns {string} The generated handler ID.
   */
  generateHandlerId(): string {
    const length = 16;
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }

    return result;
  }

  /**
   * Sends a command immediately without adding it to the queue.
   * @param {Command} command - The command to send.
   * @param {Uint8Array} payload - The payload to send along with the command.
   */
  sendCommandImmediately(command: Command, payload: Uint8Array) {
    const data = this.buildCommand(command, payload);
    this.send(data);
  }

  /**
   * Sends a command by adding it to the queue.
   * @param {Command} command - The command to send.
   * @param {Uint8Array} payload - The payload to send along with the command.
   */
  sendCommandThroughQueue(command: Command, payload: Uint8Array) {
    this.commandsQueue.send(command, payload);
  }

  /**
   * Begins a new transaction.
   * @param {string} [transactionName=DEFAULT_TRANSACTION_NAME] - The name of the transaction.
   * @returns {Transaction} The newly created transaction.
   */
  beginTransaction(transactionName = DEFAULT_TRANSACTION_NAME) {
    return this.transactionsManager.beginTransaction(transactionName);
  }

  /**
   * Executes a transaction.
   * @param {string} [transactionName=DEFAULT_TRANSACTION_NAME] - The name of the transaction.
   * @returns {Transaction} The transaction that was executed.
   */
  executeTransaction(transactionName = DEFAULT_TRANSACTION_NAME) {
    return this.transactionsManager.executeTransaction(transactionName);
  }

  /**
   * Entry point for sending commands to the robo. Commands are handled based on the following priority:
   * 1. If a transaction is provided as parameter, the command is added to that transaction
   * 2. If there is an active transaction in the TransactionsManager, the command is added to it
   * 3. If no transaction is available, the command is sent through the command queue
   *
   * @param {Command} command - The command to send to the robot
   * @param {Uint8Array} payload - The binary payload associated with the command
   * @param {Transaction | null} [useTransaction] - Optional transaction to add the command to.
   * If provided, the command will be added to this transaction instead of the active transaction
   * or command queue. Use with caution - prefer using beginTransaction() and executeTransaction()
   * methods for transaction management.
   */
  sendCommand(command: Command, payload: Uint8Array, useTransaction?: Transaction | null) {
    const transaction = useTransaction ?? this.transactionsManager.getActiveTransaction();

    if (transaction) {
      transaction.addCommand(command, payload);
    } else {
      this.sendCommandThroughQueue(command, payload);
    }
  }

  /**
   * Builds a command packet with the given command and payload.
   * @param {Command} command - The command to send to the robot.
   * @param {Uint8Array} payload - The payload to send along with the command.
   * @returns {Uint8Array} - The command packet.
   */
  buildCommand(command: Command, payload: Uint8Array): Uint8Array {
    return RoboClient.staticBuildCommand(command, payload);
  }

  /**
   * Builds a command packet with the given command and payload.
   * @param {Command} command - The command to send to the robot.
   * @param {Uint8Array} payload - The payload to send along with the command.
   * @returns {Uint8Array} - The command packet.
   */
  static staticBuildCommand(command: Command, payload: Uint8Array): Uint8Array {
    const payloadSize = payload.byteLength;
    const packetSize = 2 + payloadSize; // 2 bytes for headers
    const data = new Uint8Array(packetSize + 1);

    data[0] = packetSize;
    data[1] = command;
    data[2] = payloadSize;

    for (let i = 0; i < payloadSize; i++) {
      data[i + 3] = payload[i];
    }

    return data;
  }

  /**
   * Builds an array of payloads from an array of commands.
   * Splits commands into multiple payloads if they exceed the maximum size.
   * @param client - The RoboClient instance to use for building commands.
   * @param commands - The commands to build payloads from.
   * @returns An array of payloads.
   */
  buildMultipleCommands(commands: TransactionCommandsQueue): Uint8Array[] {
    // Multiple Commands Structure
    // "Web App message limit 16500 bytes Mobile App Limit ~517 Bytes
    // Total Message Size, CMD ID 1, CMD Payload Size 1, CMD Data ... , CMD ID 2, CMD Payload Size 2, CMD Data ... , CMD ID 3, CMD Payload Size 3, CMD Data ... etc"

    const packetSizeSize = 1; // 1 bytes for the packet size
    const commandIdSize = 1; // 1 bytes for the command id

    // Helper function to calculate the total size of commands in an array
    const getSizeOfArrayOfUint8Arrays = (commands: Uint8Array[]) =>
      commands.reduce((acc, command) => acc + command.length, 0);

    // Helper function to create a payload from a set of commands
    const createPayload = (commands: Uint8Array[]) => {
      const commandsPayloadSize = getSizeOfArrayOfUint8Arrays(commands);
      const totalSize = commandsPayloadSize + packetSizeSize + commandIdSize;
      const payload = new Uint8Array(totalSize);
      payload[0] = 0xff; // 0xFF is the packet size and it is fixed
      payload[1] = Commands.CMD_MULTIPLE_COMMAND;

      let offset = 2;
      for (const command of commands) {
        payload.set(command, offset);
        offset += command.length;
      }
      return payload;
    };

    const commandsPayloads: Uint8Array[] = commands
      .map(({ command, payload }) => this.buildCommand(command, payload))
      .map(command => command.subarray(1)); // remove first byte as it is the packet size

    let commandsBuffer: Uint8Array[] = [];
    const multipleCommandsOfValidSize: Uint8Array[][] = [];

    while (commandsPayloads.length > 0) {
      const command = commandsPayloads.shift();
      if (!command) {
        break;
      }
      if (
        command.length + getSizeOfArrayOfUint8Arrays(commandsBuffer) + packetSizeSize + commandIdSize <
        (this.transport instanceof BluetoothTransport ? ROBO_BLUETOOTH_MAX_COMMAND_SIZE : ROBO_MQTT_MAX_COMMAND_SIZE)
      ) {
        commandsBuffer.push(command);
      } else {
        multipleCommandsOfValidSize.push(commandsBuffer);
        commandsBuffer = [command];
      }
    }
    if (commandsBuffer.length > 0) {
      multipleCommandsOfValidSize.push(commandsBuffer);
    }

    return multipleCommandsOfValidSize.map(createPayload);
  }

  /**
   * Determines if the RoboClient supports multiple commands based on its firmware version.
   *
   * @returns {boolean} True if the firmware version is greater than or equal to the version
   * required for supporting multiple commands, otherwise false.
   */
  supportsMultipleCommands() {
    return (
      this.firmwareVersion &&
      compareSemanticVersions(
        `${this.firmwareVersion.major}.${this.firmwareVersion.minor}.${this.firmwareVersion.build}`,
        FIRMWARE_MAIN_BLOCK_MULTIPLE_COMMANDS_SUPPORTS_FROM
      ) >= 0
    );
  }

  /**
   * Starts a batch sensors check.
   * @param {ModuleId[] | null} [modulesIds=null] - The IDs of the modules to check, or null to check all sensors.
   */
  startBatchSensorsCheck(modulesIds: ModuleId[] | null = null) {
    this.batchSensorsFetcher.start(modulesIds);
  }

  /**
   * Stops the batch sensors check.
   */
  stopBatchSensorsCheck() {
    this.batchSensorsFetcher.stop();
  }

  /**
   * Checks if the batch sensors check is currently running.
   * @returns {boolean} - Returns true if the batch sensors check is active, otherwise false.
   */
  isRunningBatchSensorsCheck() {
    return this.batchSensorsFetcher.isRunning();
  }

  /**
   * Starts a timer to periodically log the frequency of messages sent to the robot.
   * The frequency is calculated as the number of messages sent within the last
   * {@link RoboClientOptions.profileMessagesFrequencyWindow} milliseconds.
   * @protected
   */
  startProfileMessagesFrequency() {
    this.profileMessagesFrequencyIntervalId = setInterval(() => {
      const now = Date.now();
      // Clean up old timestamps
      while (
        this.messagesTimestamps.length > 0 &&
        this.messagesTimestamps[0] < now - this.profileMessagesFrequencyWindow
      ) {
        this.messagesTimestamps.shift();
      }

      this.currentMessagesFrequency = this.messagesTimestamps.length;

      console.log(
        `RoboClient: sent messages rate: ${this.currentMessagesFrequency} messages / ${Math.round(this.profileMessagesFrequencyWindow / 1000)}s`
      );
    }, this.profileMessagesFrequencyWindow);
  }

  /**
   * todo rework
   *
   * Handles incoming messages from the transport.
   * @param message - The transport message
   */
  private onMessageReceivedHandler(message: TransportMessage) {
    // For MQTT, we need to check the topic
    if (message.topic && message.topic !== `robo/${this.roboIdentifier}/transmit`) {
      return;
    }

    // Check if data has at least two bytes (command and payload size)
    const payloadBytes = ensureTypedArray(message.data);
    if (payloadBytes.length >= 2) {
      const command = payloadBytes[0];
      const payloadSize = payloadBytes[1];
      // Check if the actual payload size matches the expected size
      if (payloadBytes.length === payloadSize + 2) {
        // Extract the payload from the received bytes
        const payload = payloadBytes.slice(2);

        // Call the receiveCommand function with the parsed values
        this.onCommandReceivedHandler(command, payload as Uint8Array);
      } else {
        console.warn('Invalid payload size.');
      }
    } else {
      console.warn('Received message is too short.');
    }
  }

  /**
   * todo rework
   *
   * Registers a handler function for the specified command.
   * If a targetId is provided, the handler will only be called for messages sent to that target.
   * Otherwise, the handler will be called for all messages broadcasted to the client.
   *
   * @param handlerType - The command to register the handler for.
   * @param handler - The handler function to register.
   * @param targetId - The optional target ID to register the handler for.
   * @returns A function that can be called to unregister the handler.
   */
  registerHandler(
    handlerType: HandlerType,
    handler: ResponseHandlerFunction,
    targetId: string | null = null
  ): () => void {
    if (targetId === null) {
      return this.registerBroadcastHandler(handlerType, handler);
    } else {
      targetId = targetId.toString();
      return this.registerTargetHandler(handlerType, handler, targetId);
    }
  }

  /**
   * todo rework
   *
   * Registers a target handler for the given command and target ID.
   * @param handlerType - The command to register the handler for.
   * @param handler - The handler function to register.
   * @param targetId - The ID of the target to register the handler for.
   * @returns A function that can be called to unregister the handler.
   */
  registerTargetHandler(handlerType: HandlerType, handler: ResponseHandlerFunction, targetId: string): () => void {
    targetId = targetId.toString();

    if (!this.targetHandlers[handlerType]) {
      this.targetHandlers[handlerType] = {};
    }

    if (!this.targetHandlers[handlerType][targetId]) {
      this.targetHandlers[handlerType][targetId] = {};
    }

    const handlerId = this.generateHandlerId();

    this.targetHandlers[handlerType][targetId][handlerId] = handler;

    return () => {
      this.unregisterHandler(handlerType, handlerId, targetId);
    };
  }

  /**
   * Registers a broadcast handler for the given command.
   * @param handlerType - The command to register the handler for.
   * @param handler - The handler function to register.
   * @returns A function that can be called to unregister the handler.
   */
  registerBroadcastHandler(handlerType: HandlerType, handler: ResponseHandlerFunction): () => void {
    if (!this.broadcastHandlers[handlerType]) {
      this.broadcastHandlers[handlerType] = {};
    }

    const handlerId = this.generateHandlerId();

    this.broadcastHandlers[handlerType][handlerId] = handler;

    return () => {
      this.unregisterHandler(handlerType, handlerId);
    };
  }

  /**
   * Unregisters a handler for the given command and target ID (if provided).
   * @param handlerType - The command to unregister the handler for.
   * @param handlerId - The ID of the handler to unregister.
   * @param targetId - The ID of the target to unregister the handler for (if applicable).
   */
  unregisterHandler(handlerType: HandlerType, handlerId: string, targetId: string | null = null) {
    if (targetId !== null) {
      targetId = targetId.toString();
      if (
        this.targetHandlers[handlerType] &&
        this.targetHandlers[handlerType][targetId] &&
        this.targetHandlers[handlerType][targetId][handlerId]
      ) {
        delete this.targetHandlers[handlerType][targetId][handlerId];
      } else {
        console.warn('unregisterHandler cannot find handler', handlerType, targetId, handlerId);
      }
    } else if (this.broadcastHandlers[handlerType] && this.broadcastHandlers[handlerType][handlerId]) {
      delete this.broadcastHandlers[handlerType][handlerId];
    }
  }

  /**
   * Unregisters all handlers for a given command.
   *
   * @param targetHandler - The command to unregister handlers for.
   */
  unregisterHandlersForHandlerType(handlerType: HandlerType) {
    if (this.broadcastHandlers[handlerType]) {
      delete this.broadcastHandlers[handlerType];
    }

    if (this.targetHandlers[handlerType]) {
      delete this.targetHandlers[handlerType];
    }
  }

  /**
   * Invokes the handlers for a given command with the provided payload.
   * @param handlerType - The command to invoke the handlers for.
   * @param payload - The payload to pass to the handlers.
   * @param targetId - The initiator of the command, if any.
   * @throws An error if the command is not registered.
   */
  invokeHandlers(handlerType: HandlerType, payload: any, targetId: string | null = null) {
    try {
      if (targetId === null) {
        // Check if there are any handlers registered for the given command
        if (!this.broadcastHandlers[handlerType]) {
          return;
        }

        Object.values(this.broadcastHandlers[handlerType]).forEach(handler => {
          handler(payload);
        });
      } else {
        targetId = targetId.toString();
        // Check if there are any handlers registered for the given command and target ID
        if (!this.targetHandlers[handlerType] || !this.targetHandlers[handlerType][targetId]) {
          return;
        }

        Object.values(this.targetHandlers[handlerType][targetId]).forEach(handler => {
          handler(payload);
        });
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // HANDLERS  -------------------------------------------------------------------------------------------
  // These functions are called when a command is received from the robot.
  // They are responsible for parsing the payload and invoking the appropriate handlers.
  onRoboConfiguration(payload: Uint8Array) {
    this.config = payload;

    const roboModel = this.getModel();
    roboModel.updateConfig(this.config);

    this.invokeHandlers(HandlerType.OnRoboConfiguration, payload);
  }

  onFirmwareUpdate(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnFirmwareUpdate, payload);
  }

  onFirmwareVersion(payload: Uint8Array) {
    const firmware: FirmwareVersion = {
      major: String.fromCharCode(payload[0]),
      minor: String.fromCharCode(payload[2]),
      build: String.fromCharCode(payload[4]),
    };

    this.firmwareVersion = firmware;
    this.version = parseInt(firmware.major);

    this.invokeHandlers(HandlerType.OnFirmwareVersion, firmware);
  }

  onBattery(payload: Uint8Array) {
    // todo!: use BatteryStatus type
    const batteryStatuses = {
      0: 'unknown',
      1: 'discharging',
      2: 'charging',
      3: 'charged',
    };

    const battery = {
      capacity: payload[0],
      status: batteryStatuses[payload[1] as keyof typeof batteryStatuses],
    };

    this.invokeHandlers(HandlerType.OnBattery, battery);
  }

  onBatteryAlert(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnBatteryAlert, payload);
  }

  onSoundLoudness(payload: Uint8Array) {
    const loudness = {
      level: payload[0],
    };

    this.invokeHandlers(HandlerType.OnSoundLoudness, loudness);
  }

  onWiFiScan(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnWiFiScan, payload);
  }

  onRunProgram(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnRunProgram, payload);
  }

  onModuleUpdate(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnModuleUpdate, payload);
  }

  onLedRGB(payload: Uint8Array) {
    const targetId = payload[0];

    this.invokeHandlers(HandlerType.OnLedRGB, payload, `${targetId}`);
    this.invokeHandlers(HandlerType.OnLedRGB, payload);
  }

  onSounds(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnSounds, payload);
  }

  onSound(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnSound, payload);
  }

  onLightLevel(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnLightLevel, payload);
  }

  onSoundLevel(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnSoundLevel, payload);
  }

  onMotionDetector(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnMotionDetector, payload);
  }

  onProximity(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnProximity, payload);
  }

  onButton(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnButton, payload);
  }

  onLinetracker(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnLinetracker, payload);
  }

  onAccelerometerState(payload: Uint8Array) {
    const targetId = payload[3];
    const targetName = RoboModel.getModuleId(ModulesCollectionTypes.Accelerometer, targetId);
    this.invokeHandlers(HandlerType.OnAccelerometerState, payload, targetName);
  }

  onAccelerometer(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnAccelerometer, payload);
  }

  onGyro(payload: Uint8Array) {
    this.invokeHandlers(HandlerType.OnGyro, payload);
  }

  onBatchSensors(payload: Uint8Array) {
    const batchResponse = {
      index: payload[0],
      data: payload.slice(1),
    };

    this.invokeHandlers(HandlerType.OnBatchSensors, batchResponse);
  }

  onActionOrTriggerResponse(payload: Uint8Array) {
    const targetId = payload[0];
    const isError = !payload[1];

    console.debug('[EXEC] onActionOrTriggerResponse', { payload, targetId, isError });
    this.invokeHandlers(HandlerType.OnActionOrTriggerResponse, { targetId, isError }, `${targetId}`);
  }

  onCommand(command: Command, payload: Uint8Array) {
    console.log('Command', command, payload);
  }

  /**
   * Handles incoming commands from the robot and performs the appropriate action.
   * @param command - The command received from the robot.
   * @param payload - The payload received along with the command.
   */
  onCommandReceivedHandler(command: Command, payload: Uint8Array) {
    switch (command) {
      case Command.CMD_CONFIGURATION: // 0x01
        this.onRoboConfiguration(payload);
        break;

      case Command.CMD_FWUPDATE_PROCESS: // 0x03
        this.onFirmwareUpdate(payload);
        break;

      case Command.CMD_FWVERSION: // 0x07
        this.onFirmwareVersion(payload);
        break;

      case Command.CMD_BAT_STATE: // 0x10
        this.onBattery(payload);
        break;

      case Command.CMD_LOW_BAT_ALLERT: // 0x11
        this.onBatteryAlert(payload);
        break;

      case Command.CMD_SOUND_LOUDNESS: // 0x12
        this.onSoundLoudness(payload);
        break;

      case Command.CMD_WIFI_SCAN: // 0x0A
        this.onWiFiScan(payload);
        break;

      case Command.CMD_RUN_STOP: // 0x30
        console.debug('[EXEC] CMD_RUN_STOP RECEIVED', payload);
        this.onRunProgram(payload);
        break;

      case Command.CMD_MODULES_FWUPDATE: // 0x49
        this.onModuleUpdate(payload);
        break;

      case Command.CMD_MOTOR_SPEED: // 0x50
        // ignore this command to not pollute console
        break;

      case Command.CMD_RGB: // 0x53
        this.onLedRGB(payload);
        break;

      case Command.CMD_MOTOR_ANGLE: // 0x5b
        // ignore this command to not pollute console
        break;

      case Command.CMD_AVAILABLE_SOUNDS: // 0x60
        this.onSounds(payload);
        break;

      case Command.CMD_PLAY_SOUND: // 0x61
        this.onSound(payload);
        break;

      case Command.CMD_LIGHT_LEVEL: // 0x80
        this.onLightLevel(payload);
        break;

      case Command.CMD_SOUND_LEVEL: // 0x81
        this.onSoundLevel(payload);
        break;

      case Command.CMD_MOTION_DET: // 0x83
        this.onMotionDetector(payload);
        break;

      case Command.CMD_GET_DISTANCE: // 0x84
        this.onProximity(payload);
        break;

      case Command.CMD_GET_BUTTON: // 0x85
        this.onButton(payload);
        break;

      case Command.CMD_GET_LINETRACKER: // 0x86
        this.onLinetracker(payload);
        break;

      case Command.CMD_ACCELEROMETER_STATE: // 0x87
        this.onAccelerometerState(payload);
        break;

      case Command.CMD_GET_ACC: // 0x89
        this.onAccelerometer(payload);
        break;

      case Command.CMD_GET_GYR: // 0x8A
        this.onGyro(payload);
        break;

      case Command.CMD_BATCH_SENSORS: // 0x90
        this.onBatchSensors(payload);
        break;

      case Command.CMD_ACTION_OR_TRIGGER_RESPONSE: // 0xC0
        this.onActionOrTriggerResponse(payload);
        break;

      default:
        console.warn('Unhandled command', command, payload);
        break;
    }
  }

  // COMMANDS -------------------------------------------------------------------------------------------

  requestRoboConfiguration() {
    this.sendCommand(Command.CMD_CONFIGURATION, new Uint8Array(0)); // 0x01
  }

  requestBattery() {
    this.sendCommand(Command.CMD_BAT_STATE, new Uint8Array(0)); // 0x10
  }

  requestLoudness() {
    this.sendCommand(Command.CMD_SOUND_LOUDNESS, new Uint8Array([0x00])); // 0x12
  }

  setLoudness(loudness = 100) {
    this.sendCommand(Command.CMD_SOUND_LOUDNESS, new Uint8Array([0x01, loudness])); // 0x12
  }

  requestFirmwareVersion() {
    this.sendCommand(Command.CMD_FWVERSION, new Uint8Array(0)); // 0x07
  }

  /*
   * This update method works only if MB can connect to Wi-Fi by itself.
   * Means that MB knows Wi-Fi SSID and password and bridge security type is not WIFI_AUTH_WPA2_ENTERPRISE
   */
  startFirmwareUpdate() {
    const body = new Uint8Array([0x01]);
    this.sendCommand(Command.CMD_FWUPDATE, body); // 0x02
  }

  firmwareUpdate(start: boolean, local: boolean) {
    const body = new Uint8Array([start ? 1 : 0, local ? 1 : 0]);
    this.sendCommand(Command.CMD_FWUPDATE_PROCESS, body); // 0x03
  }

  firmwareUpdateGetIP() {
    this.sendCommand(Command.CMD_FWUPDATE_PROCESS, new Uint8Array([0x03])); // 0x03
  }

  firmwareUpdateDisconnect() {
    this.sendCommand(Command.CMD_FWUPDATE_PROCESS, new Uint8Array([0x04])); // 0x03
  }

  setRoboName(name: string) {
    const body = new TextEncoder().encode(name.slice(0, ROBO_NAME_MAX_CHARACTERS_COUNT));
    this.sendCommand(Command.CMD_BT_NAME, body); // 0x06
  }

  wifiScan() {
    this.sendCommand(Command.CMD_WIFI_SCAN, new Uint8Array(0)); // 0x0A
  }

  setApplication(application: number) {
    const body = new Uint8Array(1);
    body[0] = application;
    this.sendCommand(Command.CMD_APP_TYPE, body); // 0x20
  }

  setRunCommand(run: boolean) {
    const body = new Uint8Array(1);
    body[0] = run ? 1 : 0;
    this.sendCommandImmediately(Command.CMD_RUN_STOP, body); // 0x30
    if (!run) {
      this.commandsQueue.cleanup();
    }
  }

  setWiFiSSID(ssid: string) {
    const bytes = new TextEncoder().encode(ssid + '\0');
    let start = 0;

    while (start < bytes.length) {
      const length = Math.min(bytes.length - start, 17);

      const body = new Uint8Array(length);
      body.set(bytes.slice(start, start + length), 0);

      this.sendCommand(Command.CMD_SET_WIFI_SSID, body); // 0x45

      start += length;
    }
  }

  setWiFiPassword(password: string) {
    const bytes = new TextEncoder().encode(password + '\0');
    let start = 0;

    while (start < bytes.length) {
      const length = Math.min(bytes.length - start, 17);

      const body = new Uint8Array(length);
      body.set(bytes.slice(start, start + length), 0);

      this.sendCommand(Command.CMD_SET_WIFI_PSK, body); // 0x46

      start += length;
    }
  }

  moduleUpdate(address: number, type: number) {
    const body = new Uint8Array([address, type]);
    this.sendCommand(Command.CMD_MODULES_FWUPDATE, body); // 0x49
  }

  setMotor(index: number, speed: number) {
    const body = new Uint8Array([index, speed]);
    this.sendCommand(Command.CMD_MOTOR_SPEED, body); // 0x50
  }

  setServo(index: number, angle: number) {
    const body = new Uint8Array([index, angle]);
    this.sendCommand(Command.CMD_SERVO_POSITION, body); // 0x51
  }

  setMatrix(matrix: Uint8Array, index: number) {
    const body = new Uint8Array(9);
    body.set(matrix.subarray(0, 8), 0);
    body[8] = index;
    this.sendCommand(Command.CMD_MATRIX, body); // 0x52
  }

  /**
   * Sets the LED color and mode for a specific index.
   * @param {number} index - The index of the LED to set.
   * @param {number} mode - 0 - off, 1 - on
   * @param {Uint8Array} color - The color of the LED to set.
   */
  setLed(index: number, mode: number, color: Uint8Array) {
    const body = new Uint8Array(5);
    body[0] = index;
    body[1] = mode;
    body.set(color, 2);
    this.sendCommand(Command.CMD_RGB, body); // 0x53
  }

  setAnimation(
    id: number,
    index: number,
    animation: number,
    repeats: number,
    reverse: number,
    orientation: number,
    numFrames: number,
    framerateMs: number
  ) {
    const body = new Uint8Array(9);
    body[0] = id;
    body[1] = index;
    body[2] = animation;
    body[3] = repeats;
    body[4] = reverse;
    body[5] = orientation;
    body[6] = numFrames;
    body[7] = framerateMs >> 8;
    body[8] = framerateMs;

    this.sendCommand(Command.CMD_DISPLAY_ANIMATION, body); // 0x54
  }

  loadCustomAnimationFrame(part: number, index: number, frame: number, half: Uint8Array) {
    const body = new Uint8Array(17);
    body[0] = part | (index << 1) | (frame << 3);
    body.set(half.subarray(0, 16), 1);
    this.sendCommand(Command.CMD_DISPLAY_LOAD_ANIMATION, body); // 0x55
  }

  loadCustomText(index: number, text: string) {
    if (!isAsciiString(text)) {
      throw Error('Text contains invalid characters. Only ASCII characters are allowed.');
    }

    const textBytes = new TextEncoder().encode(text);

    const maxLength = 17;
    let offset = 0;

    while (offset < textBytes.length) {
      let length = textBytes.length - offset;
      length = Math.min(length, maxLength);

      const body = new Uint8Array(length + 3);
      body[0] = index;
      body[1] = offset;
      body[2] = length;
      body.set(textBytes.subarray(offset, offset + length), 3);

      this.sendCommand(Command.CMD_DISPLAY_LOAD_TEXT, body); // 0x56

      offset += length;
    }
  }

  loadCustomImage(part: number, index: number, imageHalf: Uint8Array) {
    const body = new Uint8Array(17);
    body[0] = part | (index << 1);
    body.set(imageHalf, 1);

    this.sendCommand(Command.CMD_DISPLAY_CUSTOM_IMAGE, body); // 0x57
  }

  setDisplayImage(id: number, index: number, image: number, orientation: number, timeMs: number) {
    const body = new Uint8Array([id, index, image, orientation, timeMs >> 8, timeMs]);
    this.sendCommand(Command.CMD_DISPLAY_IMAGE, body); // 0x58
  }

  /**
   * Sets the display text on the LED matrix.
   *
   * @param id - The action ID.
   * @param index - The index of the LED display module.
   * @param orientation - The orientation of the text.
   * @param length - The length of the text.
   * @param rateMs - The rate of the text. How long each character is displayed in milliseconds.
   */
  setDisplayText(id: number, index: number, orientation: number, length: number, rateMs: number) {
    const body = new Uint8Array([id, index, orientation, length, rateMs >> 8, rateMs]);
    this.sendCommand(Command.CMD_DISPLAY_SET_TEXT, body); // 0x59
  }

  displayStop(index: number) {
    const body = new Uint8Array([index]);
    this.sendCommand(Command.CMD_DISPLAY_STOP, body); // 0x5A
  }

  motorAngleAction(id: number, index: number, angle: number, ccw: boolean) {
    const body = new Uint8Array([index, id, angle >> 8, angle, ccw ? 1 : 0]);
    this.sendCommand(Command.CMD_MOTOR_ANGLE, body); // 0x5B
  }

  requestSounds() {
    this.sendCommand(Command.CMD_AVAILABLE_SOUNDS, new Uint8Array(0)); // 0x60
  }

  playSound(index: number) {
    const body = new Uint8Array([index]);
    this.sendCommand(Command.CMD_PLAY_SOUND, body); // 0x61
  }

  requestLightLevel(index: number) {
    this.sendCommand(Command.CMD_LIGHT_LEVEL, new Uint8Array([index])); // 0x80
  }

  requestSoundLevel(index: number) {
    this.sendCommand(Command.CMD_SOUND_LEVEL, new Uint8Array([index])); // 0x81
  }

  requestMotion(index: number) {
    this.sendCommand(Command.CMD_MOTION_DET, new Uint8Array([index])); // 0x83
  }

  requestUltrasonic(index: number) {
    this.sendCommand(Command.CMD_GET_DISTANCE, new Uint8Array([index])); // 0x84
  }

  requestButton(index: number) {
    this.sendCommand(Command.CMD_GET_BUTTON, new Uint8Array([index])); // 0x85
  }

  requestLinetracker(index: number) {
    this.sendCommand(Command.CMD_GET_LINETRACKER, new Uint8Array([index])); // 0x86
  }

  requestAccelerometerState(index: number) {
    this.sendCommand(Command.CMD_ACCELEROMETER_STATE, new Uint8Array([index])); // 0x87
  }

  requestAccelerometer(index: number) {
    this.sendCommand(Command.CMD_GET_ACC, new Uint8Array([index])); // 0x89
  }

  requestGyro(index: number) {
    this.sendCommand(Command.CMD_GET_GYR, new Uint8Array([index])); // 0x8A
  }

  sendBatchSensorsRequest(batchIndex: number, data: Uint8Array) {
    const body = new Uint8Array(1 + data.length);
    body[0] = batchIndex;
    body.set(data, 1);
    this.sendCommand(Command.CMD_BATCH_SENSORS, body); // 0x90
  }

  setTune(id: number, tune: number, tempo: number) {
    const body = new Uint8Array([tune, tempo, id]);
    this.sendCommand(Command.CMD_SET_TUNE, body); // 0x92
  }

  uploadCustomTune(tune: number[][]) {
    let start = 0;

    while (start < tune.length) {
      let length = tune.length - start;
      if (length > 16) length = 16;

      const body = new Uint8Array(length + 1);
      body[0] = start;
      for (let i = 0; i < length; i++) body[i + 1] = tune[start + i][0] | (tune[start + i][1] << 5);

      this.sendCommand(Command.CMD_UPLOAD_CUSTOM_TUNE, body); // 0x93

      start += length;
    }
  }

  playNote(note: number) {
    this.sendCommand(Command.CMD_PLAY_NOTE, new Uint8Array([note])); // 0x94
  }

  gyroAssistedTurn(
    id: number,
    index: number,
    motors: number,
    directions: number,
    speed: number,
    wheel: number,
    angle: number
  ) {
    const body = new Uint8Array([
      id,
      index,
      motors,
      directions,
      speed >> 8,
      speed,
      wheel >> 8,
      wheel,
      angle >> 8,
      angle,
    ]);
    this.sendCommand(Command.CMD_SET_GYRO_TURN_ACTION, body); // 0xAA
  }

  setTurn(id: number, index: number, reverse: number, speed: number, wheel: number, angle: number) {
    const body = new Uint8Array([id, index, reverse, speed >> 8, speed, wheel >> 8, wheel, angle >> 8, angle]);
    this.sendCommand(Command.CMD_SET_TURN_ACTION, body); // 0xAC
  }

  /**
   * TRIGGERS
   * =====================================================================================
   */
  /**
   * Set proximity trigger (Ultrasonic distance trigger)
   * @param triggerId Unique Trigger id (0-255)
   * @param moduleIndex Module index
   * @param condition Condition for triggering
   * @param value Value for the condition
   */
  setProximityTrigger(triggerId: number, moduleIndex: number, condition: number, value: number) {
    const body = new Uint8Array([triggerId, moduleIndex, condition, value]);
    this.sendCommand(Command.CMD_SET_DISTANCE_TRIGGER, body); // 0xB0
  }

  /**
   * Set button trigger
   * @param triggerId Unique Trigger id (0-255)
   * @param moduleIndex Button index
   * @param condition 0: pressed, -1: released, any positive value: pressed for this many times
   */
  setButtonTrigger(triggerId: number, moduleIndex: number, condition: number) {
    // Convert condition to 8-bit signed integer
    // old: new Int8Array([+condition])[0];
    const condition8Bit = (+condition << 24) >> 24;

    const body = new Uint8Array([+triggerId, +moduleIndex, condition8Bit]);

    this.sendCommand(Command.CMD_SET_BUTTON_TRIGGER, body); // 0xb1
  }

  /**
   * Set light trigger
   * @param triggerId Unique Trigger id (0-255)
   * @param moduleIndex Module index
   * @param condition Condition for triggering
   * @param value Value for the condition
   */
  setLightTrigger(triggerId: number, moduleIndex: number, condition: number, value: number) {
    const body = new Uint8Array([triggerId, moduleIndex, condition, value & 0xff, (value >> 8) & 0xff]);

    this.sendCommand(Command.CMD_SET_LIGHT_TRIGGER, body); // 0xB2
  }

  /**
   * Set motion trigger
   * @param triggerId Unique Trigger id (0-255)
   * @param moduleIndex Module index
   * @param motion Motion value
   */
  setMotionTrigger(triggerId: number, moduleIndex: number, motion: number) {
    const body = new Uint8Array([triggerId, moduleIndex, motion]);

    this.sendCommand(Command.CMD_SET_MOTION_TRIGGER, body); // 0xB3
  }

  /**
   * Set linetracker trigger
   * @param triggerId Unique Trigger id (0-255)
   * @param moduleIndex Module index
   * @param condition Condition for triggering
   */
  setLinetrackerTrigger(triggerId: number, moduleIndex: number, condition: number) {
    const body = new Uint8Array([triggerId, moduleIndex, condition]);

    this.sendCommand(Command.CMD_SET_LINETRACKER_TRIGGER, body); // 0xB5
  }

  /**
   * Set accelerometer trigger
   * @param triggerId Unique Trigger id (0-255)
   * @param moduleIndex Module index
   * @param condition (0: pick up, 1: put down, 2: move)
   */
  setAccelerometerTrigger(triggerId: number, moduleIndex: number, condition: number) {
    const body = new Uint8Array([triggerId, moduleIndex, condition]);
    this.sendCommand(Command.CMD_SET_ACCELEROMETER_TRIGGER, body); // 0xB6
  }

  /**
   * ACTIONS
   * =====================================================================================
   */

  /**
   * Set motor action
   * @param actionId action id
   * @param moduleIndex module index
   * @param speed speed (%)
   * @param wheel wheel diameter (mm)
   * @param distance distance (mm)
   */
  setMotorAction(actionId: number, moduleIndex: number, speed: number, wheel: number, distance: number) {
    const body = new Uint8Array([
      actionId,
      moduleIndex,
      speed >> 8,
      speed & 0xff,
      wheel >> 8,
      wheel & 0xff,
      distance >> 8,
      distance & 0xff,
    ]);

    this.sendCommand(Command.CMD_SET_MOTOR_ACTION, body); // 0xA0
  }

  /**
   * Set servo action
   * @param actionId action id
   * @param moduleIndex module index
   * @param angle angle
   */
  setServoAction(actionId: number, moduleIndex: number, angle: number) {
    const body = new Uint8Array([actionId, moduleIndex, angle >> 8, angle & 0xff]);

    this.sendCommand(Command.CMD_SET_SERVO_ACTION, body); // 0xA1
  }

  /**
   * Set LED action
   * @param actionId Action ID
   * @param moduleIndex LED index
   * @param red Red color value (0-255)
   * @param green Green color value (0-255)
   * @param blue Blue color value (0-255)
   * @param time Duration in milliseconds
   * @param blink Blink frequency
   */
  setLedAction(
    actionId: number,
    moduleIndex: number,
    red: number,
    green: number,
    blue: number,
    time: number,
    blink: number
  ) {
    const mode = 0;

    const body = new Uint8Array([
      actionId,
      moduleIndex,
      red,
      green,
      blue,
      (time >> 8) & 0xff,
      time & 0xff,
      blink,
      mode,
    ]);
    this.sendCommand(Command.CMD_SET_RGB_ACTION, body); // 0xA2
  }

  /**
   * Set LED screen action DEPRECATED
   * @param actionId Action ID
   * @param moduleIndex Screen index
   * @param rows Array of 8 bytes representing the LED screen rows
   * @param time Duration in milliseconds
   * @deprecated
   */
  setLedScreenAction(actionId: number, moduleIndex: number, rows: Uint8Array, time: number) {
    if (rows.length !== 8) {
      throw new Error('rows must be an array of 8 bytes');
    }

    const body = new Uint8Array(12);
    body[0] = actionId;
    body[1] = moduleIndex;
    body.set(rows, 2);
    body[10] = (time >> 8) & 0xff;
    body[11] = time & 0xff;

    this.sendCommand(Command.CMD_SET_MATRIX_ACTION, body); // 0xA3
  }

  /**
   * Play sound action
   * @param actionId Action ID
   * @param sound Sound index
   */
  setPlaySoundAction(actionId: number, sound: number) {
    const body = new Uint8Array([actionId, sound]);

    this.sendCommand(Command.CMD_SET_PLAY_SOUND_ACTION, body); // 0xA4
  }

  /**
   * Set drive action
   * @param actionId Action ID
   * @param motorsBitmask Motors bitmask
   * @param directionsBitmask Directions bitmask (0 - ccw, 1 - cw)
   * @param speed Speed value (0-100%)
   * @param wheel Wheel diameter (mm)
   * @param distance Distance to travel (mm)
   */
  setDriveAction(
    actionId: number,
    motorsBitmask: number,
    directionsBitmask: number,
    speed: number,
    wheel: number,
    distance: number
  ) {
    const body = new Uint8Array(9);
    body[0] = actionId;
    body[1] = motorsBitmask;
    body[2] = directionsBitmask;
    body[3] = (speed >> 8) & 0xff;
    body[4] = speed & 0xff;
    body[5] = (wheel >> 8) & 0xff;
    body[6] = wheel & 0xff;
    body[7] = (distance >> 8) & 0xff;
    body[8] = distance & 0xff;

    this.sendCommand(Command.CMD_SET_DRIVE_ACTION, body); // 0xA6
  }

  /**
   * Set linetracker action
   * @param actionId Action ID
   * @param moduleIndex Module index
   * @param motors Motors to use
   * @param directions Directions to move
   * @param speed Speed value
   * @param kp Proportional gain
   * @param kd Derivative gain
   */
  setLineTrackerAction(
    actionId: number,
    moduleIndex: number,
    motors: number,
    directions: number,
    speed: number,
    kp: number,
    kd: number
  ) {
    const body = new Uint8Array(9);
    body[0] = actionId;
    body[1] = moduleIndex;
    body[2] = motors;
    body[3] = directions;
    body[4] = speed;
    body[5] = (kp >> 8) & 0xff;
    body[6] = kp & 0xff;
    body[7] = (kd >> 8) & 0xff;
    body[8] = kd & 0xff;

    this.sendCommand(Command.CMD_SET_LINETRACKER_ACTION, body); // 0xA9
  }

  /**
   * Set gyro-assisted turn action
   * @param actionId Action ID
   * @param accelerometerModuleIndex Module index
   * @param motorsBitmask Motors bitmask
   * @param directionsBitmask Directions bitmask
   * @param speed Speed value (0-100%)
   * @param wheel Wheel value (mm)
   * @param angle Angle to turn (-360 to 360)
   */
  setGyroAssistedTurnAction(
    actionId: number,
    accelerometerModuleIndex: number,
    motorsBitmask: number,
    directionsBitmask: number,
    speed: number,
    wheel: number,
    angle: number
  ) {
    const body = new Uint8Array(10);
    body[0] = actionId;
    body[1] = accelerometerModuleIndex;
    body[2] = motorsBitmask;
    body[3] = directionsBitmask;
    body[4] = (speed >> 8) & 0xff;
    body[5] = speed & 0xff;
    body[6] = (wheel >> 8) & 0xff;
    body[7] = wheel & 0xff;
    body[8] = (angle >> 8) & 0xff;
    body[9] = angle & 0xff;

    this.sendCommand(Command.CMD_SET_GYRO_TURN_ACTION, body); // 0xAA
  }

  /**
   * Set drive steering action
   * @param actionId Action ID
   * @param motorsBitmask Motors bitmask
   * @param directionsBitmask Directions bitmask (0 - ccw, 1 - cw)
   * @param speed Speed value (0-100%)
   * @param wheel Wheel diameter (mm)
   * @param distance Distance (mm)
   * @param steering Steering value
   */
  setDriveSteeringAction(
    actionId: number,
    motorsBitmask: number,
    directionsBitmask: number,
    speed: number,
    wheel: number,
    distance: number,
    steering: number
  ) {
    const body = new Uint8Array(10);
    body[0] = actionId;
    body[1] = motorsBitmask;
    body[2] = directionsBitmask;
    body[3] = (speed >> 8) & 0xff;
    body[4] = speed & 0xff;
    body[5] = (wheel >> 8) & 0xff;
    body[6] = wheel & 0xff;
    body[7] = (distance >> 8) & 0xff;
    body[8] = distance & 0xff;
    body[9] = steering;

    this.sendCommand(Command.CMD_SET_STEER_ACTION, body); // 0xAB
  }

  /**
   * Set turn action
   * @param actionId Action ID
   * @param motorsBitmask Motors bitmask
   * @param directionsBitmask Directions bitmask
   * @param speed Speed value (0-100%)
   * @param wheel Wheel diameter (mm)
   * @param angle Angle value (0 to 360)
   */
  setTurnAction(
    actionId: number,
    motorsBitmask: number,
    directionsBitmask: number,
    speed: number,
    wheel: number,
    angle: number
  ) {
    const body = new Uint8Array([
      actionId,
      motorsBitmask,
      directionsBitmask,
      (speed >> 8) & 0xff,
      speed & 0xff,
      (wheel >> 8) & 0xff,
      wheel & 0xff,
      (angle >> 8) & 0xff,
      angle & 0xff,
    ]);

    this.sendCommand(Command.CMD_SET_TURN_ACTION, body); // 0xAC
  }

  /**
   * OTHER
   * =====================================================================================
   * @todo: Extract to separate file
   */

  /**
   * Get display alias
   * @param alias alias
   * @returns alias without RW_ prefix
   */
  static getDisplayAlias(alias: string) {
    return alias.replace(/^RW_/, '');
  }

  isBluetoothTransport() {
    return this.transport && this.transport instanceof BluetoothTransport;
  }

  /**
   * Uploads firmware to the robot in a series of chunks. This method sends each chunk of the firmware data
   * to the robot, ensuring that each piece is received and processed before continuing with the next. It leverages
   * an asynchronous iterator to fetch chunks lazily, allowing for efficient use of memory and network resources.
   *
   * @async
   * @method
   * @param {AsyncIterable<Record<FileChunk>>} chunksAsyncList - An async iterable that yields objects representing firmware chunks.
   * Each chunk object should have the following properties:
   *   - `index` (number): The index of the current chunk, starting from 0.
   *   - `data` (ArrayBuffer): The binary data of the chunk.
   *   - `contentLength` (number): The total length of the firmware being uploaded. This is only required for the first chunk.
   *   - `start` (number): The starting byte position of the current chunk in the total firmware. This is not required for the first chunk.
   * @param {Object} [options={}] - Optional parameters for the upload operation.
   * @param {number} [options.timeout=30000] - The maximum amount of time (in milliseconds) to wait for the entire upload process to complete.
   * @param {CancelToken} [options.cancelToken=null]
   * @param {function} [options.onProgress=null]
   *
   * @returns {Promise<void>} A promise that resolves when the firmware upload is complete, or rejects if the upload fails
   * or the operation times out.
   *
   * @example
   * // Assuming `getFirmwareChunks()` is an async generator function that yields firmware chunks
   * const chunksAsyncList = getFirmwareChunks();
   * const options = { timeout: 60000 }; // 60 seconds
   * roboClient.uploadFirmware(chunksAsyncList, options)
   *   .then(() => console.log('Firmware upload completed successfully.'))
   *   .catch(error => console.error('Firmware upload failed:', error));
   */
  async uploadFirmware(
    chunksAsyncList: AsyncIterable<FileChunk>,
    options: {
      cancelToken?: CancelToken;
      timeout: number;
      onProgress?: (progress: number) => void;
    }
  ): Promise<void> {
    let isTimeoutExceeded = false;
    let isCanceled = false;

    if (options.cancelToken) {
      options.cancelToken.subscribe(() => {
        isCanceled = true;
      });
    }

    const timeoutExceededError = {
      error: `Timeout exceeded ${options.timeout}`,
    };

    const result = await Promise.race<{ error?: string; done?: boolean; canceled?: boolean }>([
      new Promise(resolve => {
        setTimeout(() => {
          isTimeoutExceeded = true;
          resolve(timeoutExceededError);
        }, options.timeout);
      }),
      (async () => {
        options.onProgress?.(0);
        // upload format is detected by second byte of response after initial chunk has been sent
        const maxChunkSize =
          this.transport instanceof BluetoothTransport
            ? ROBO_FIRMWARE_BLUETOOTH_MAX_CHUNK_SIZE
            : ROBO_FIRMWARE_MQTT_MAX_CHUNK_SIZE;

        for await (const chunk of chunksAsyncList) {
          if (isTimeoutExceeded) {
            return timeoutExceededError;
          }
          if (isCanceled) {
            return {
              canceled: true,
            };
          }
          if (chunk.data.byteLength > maxChunkSize) {
            throw new Error(
              `Chunk size ${chunk.data.byteLength} exceeds maximum allowed size of ${maxChunkSize} bytes`
            );
          }
          // Chunk header - 13 bytes
          const chunkMessageHeader = new Uint8Array([
            0xff,
            0x08,
            0xff,
            ...convertUnsignedNumberToBytes(chunk.index, 2, false), // chunk index
            ...convertUnsignedNumberToBytes(chunk.data.byteLength, 4, false), // chunk size
            ...convertUnsignedNumberToBytes(chunk.index === 0 ? chunk.contentLength : chunk.start, 4, false), //chunk offset
          ]);

          let mainBlockIsReadyToReceiveRest;
          if (chunk.index === 0) {
            mainBlockIsReadyToReceiveRest = this.transport.waitForSpecificMessage((message, resolve) => {
              const payloadBytes = ensureTypedArray(message.data);
              // 08 xx 01 - ready to receive remaining payload (new format)
              if (payloadBytes[0] === 0x08 && payloadBytes[2] === 0x01) {
                resolve(undefined);
              }
            }, 20000);
          }

          const chunkMessage = concatUint8Arrays(chunkMessageHeader, new Uint8Array(chunk.data));
          this.send(chunkMessage);

          if (chunk.index === 0) {
            await mainBlockIsReadyToReceiveRest;
          } else {
            // with new format we need to wait for event which
            // says that MB is ready to receive next chunk
            const progress = await this.transport.waitForSpecificMessage<number>((message, resolve) => {
              const payloadBytes = ensureTypedArray(message.data);
              // 08 xx 02 - ready to receive next chunk
              if (payloadBytes[0] === 0x08 && payloadBytes[2] === 0x02) {
                resolve(payloadBytes[3]);
              }
            }, 20000);
            options.onProgress?.(progress);
          }
        }

        if (isTimeoutExceeded) {
          return timeoutExceededError;
        }

        if (isCanceled) {
          return {
            canceled: true,
          };
        }

        const updateIsFinished = this.transport.waitForSpecificMessage<undefined>((message, resolve) => {
          const payloadBytes = ensureTypedArray(message.data);
          // 08 xx 03 - update was successful (new upload format)
          if (payloadBytes[0] === 0x08 && payloadBytes[2] === 0x03) {
            resolve(undefined);
          }
        }, 20000);

        if (isTimeoutExceeded) {
          return timeoutExceededError;
        }

        if (isCanceled) {
          return {
            canceled: true,
          };
        }

        const finishPayload = new Uint8Array([0xff, 0x08, 0xff, 0xff, 0xff, 0xff, 0xff]);
        this.send(finishPayload);

        await updateIsFinished;

        return { done: true };
      })().catch(error => {
        return {
          error,
        };
      }),
    ]);

    if (result.error || result.canceled || !result.done) {
      await this.abortFirmwareUpload();

      if (result.error) {
        throw result.error;
      }
      if (result.canceled) {
        throw new Error('Firmware upload was canceled');
      }
      if (!result.done) {
        throw new Error('Firmware upload was not finished');
      }
    }
  }

  async abortFirmwareUpload() {
    const abortPayload = new Uint8Array([
      0xff,
      0x08,
      0xff,
      ...convertUnsignedNumberToBytes(0xfffe, 2, false), // chunk index
      ...convertUnsignedNumberToBytes(0, 4, false), // chunk size
      ...convertUnsignedNumberToBytes(0, 4, false), //chunk offset
    ]);
    console.info('abortFirmwareUpload', abortPayload);

    this.send(abortPayload);
  }
}
