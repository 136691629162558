import { createApi } from '@reduxjs/toolkit/query/react';
import protectedQuery from '@store/utils/protected-query.graphql';

import { GlobalEntityResponse, Maybe } from '@store/gql/graphql';
import { GLOBAL_QUERY } from '@store/queries/global';

export const globalApiGraphql = createApi({
  reducerPath: 'globalApiGraphql',
  baseQuery: protectedQuery,
  endpoints: builder => ({
    global: builder.query<{ global: Maybe<GlobalEntityResponse> }, void>({
      query: () => {
        return {
          document: GLOBAL_QUERY,
        };
      },
    }),
  }),
});

export const { useGlobalQuery, useLazyGlobalQuery } = globalApiGraphql;
