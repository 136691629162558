const SoundsMap = {
  'bell-sound': 'Bell',
  'party-sound': 'Party',
  'car-sound': 'Car',
  'chick-sound': 'Chick',
  'clock-sound': 'Clock',
  'cat-sound': 'Cat',
  'dog-sound': 'Dog',
  'robot-sound': 'Robot',
  'owl-sound': 'Owl',
  'spaceship-sound': 'Spaceship',
  'dinosaur-sound': 'Dinosaur',
  'horse-sound': 'Horse',
  'lion-sound': 'Lion',
  'rabbit-sound': 'Rabbit',
  'turkey-sound': 'Turkey',
  'rooster-sound': 'Rooster',
  'buzzer-sound': 'Buzzer',
  'fire-truck-sound': 'FireTruck',
  'cricket-sound': 'Cricket',
};

export enum SoundsMapEnum {
  BellSound = 'Bell',
  PartySound = 'Party',
  CarSound = 'Car',
  ChickSound = 'Chick',
  ClockSound = 'Clock',
  CatSound = 'Cat',
  DogSound = 'Dog',
  RobotSound = 'Robot',
  OwlSound = 'Owl',
  SpaceshipSound = 'Spaceship',
  DinosaurSound = 'Dinosaur',
  HorseSound = 'Horse',
}

export default SoundsMap;
