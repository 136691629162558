export type RGBColor = {
  red: number;
  green: number;
  blue: number;
};

export type HSLAColor = {
  hue: number;
  saturation: number;
  lightness: number;
  alpha: number;
};

export type HexColor = string;

/**
 * Converts HSLA to RGB
 * @param hue - The hue to convert
 * @param saturation - The saturation to convert
 * @param lightness - The lightness to convert
 * @returns {RGBColor} The RGB color
 */
export const hslaToRgb = (hue: number, saturation: number, lightness: number): RGBColor => {
  // Convert saturation and lightness to the range of 0 to 1
  const s = saturation / 100;
  const l = lightness / 100;

  // Calculate the chroma value (colorfulness) based on lightness and saturation
  const c = (1 - Math.abs(2 * l - 1)) * s;

  // Convert hue to the range of 0 to 6 (for easier calculation)
  const h60 = hue / 60;

  // Calculate the intermediate value for determining the RGB components
  const x = c * (1 - Math.abs((h60 % 2) - 1));

  // Calculate the offset needed for each RGB component
  const m = l - c / 2;

  // Determine the RGB values based on the hue value
  let r, g, b;
  switch (Math.floor(h60)) {
    case 0:
      r = c;
      g = x;
      b = 0;
      break;
    case 1:
      r = x;
      g = c;
      b = 0;
      break;
    case 2:
      r = 0;
      g = c;
      b = x;
      break;
    case 3:
      r = 0;
      g = x;
      b = c;
      break;
    case 4:
      r = x;
      g = 0;
      b = c;
      break;
    case 5:
    default:
      r = c;
      g = 0;
      b = x;
  }

  // Scale the RGB values to the 0-255 range and round them to integers
  return {
    red: Math.round((r + m) * 255),
    green: Math.round((g + m) * 255),
    blue: Math.round((b + m) * 255),
  };
};

/**
 * Converts RGB to hex
 * @param {number} r
 * @param {number} g
 * @param {number} b
 * @returns {HexColor} The hex color
 */
export const rgbToHex = (r: number, g: number, b: number): HexColor => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).toUpperCase().slice(1)}`;
};

/**
 * Converts hex to RGB
 * @param {string} hexColor - The hex color to convert
 * @returns {RGBColor} The RGB color
 */
export const hexToRgb = (hexColor: HexColor): RGBColor => {
  const red = parseInt(hexColor.substring(1, 3), 16);
  const green = parseInt(hexColor.substring(3, 5), 16);
  const blue = parseInt(hexColor.substring(5, 7), 16);
  return { red, green, blue };
};

/**
 * Adjusts the brightness of a hex color while preserving its hue
 * Uses HSL color space for more natural brightness adjustments
 * @param {HexColor} hexColor - The hex color to adjust (e.g., "#FF0000")
 * @param {number} brightness - Brightness percentage (0-100)
 * @returns {HexColor} The adjusted hex color
 */
export const adjustColorBrightness = (hexColor: HexColor, brightness: number): HexColor => {
  // Ensure brightness is within valid range
  brightness = Math.max(0, Math.min(100, brightness));

  // Parse the hex color
  const r = parseInt(hexColor.substring(1, 3), 16) / 255;
  const g = parseInt(hexColor.substring(3, 5), 16) / 255;
  const b = parseInt(hexColor.substring(5, 7), 16) / 255;

  // Convert RGB to HSL
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    if (max === r) h = (g - b) / d + (g < b ? 6 : 0);
    else if (max === g) h = (b - r) / d + 2;
    else if (max === b) h = (r - g) / d + 4;

    h /= 6;
  }

  // Adjust lightness based on brightness
  const adjustedL = (l * brightness) / 100;

  // Convert back to RGB
  function hue2rgb(p: number, q: number, t: number): number {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  }

  const q = adjustedL < 0.5 ? adjustedL * (1 + s) : adjustedL + s - adjustedL * s;
  const p = 2 * adjustedL - q;

  const adjustedR = Math.round(hue2rgb(p, q, h + 1 / 3) * 255);
  const adjustedG = Math.round(hue2rgb(p, q, h) * 255);
  const adjustedB = Math.round(hue2rgb(p, q, h - 1 / 3) * 255);

  // Convert back to hex
  return `#${((1 << 24) | (adjustedR << 16) | (adjustedG << 8) | adjustedB).toString(16).slice(1)}`;
};

/**
 * Get the contrast color for a given hex color
 * @param hexColor - The hex color to get the contrast color for
 * @returns {HexColor} The contrast color
 */
export const getContrastColor = (hexColor: HexColor): HexColor => {
  if (!/^#[0-9A-F]{6}$/i.test(hexColor)) {
    throw new Error(`invalid hex value ${hexColor}`);
  }
  // Parse the hex color to RGB
  const red = parseInt(hexColor.substring(1, 3), 16);
  const green = parseInt(hexColor.substring(3, 5), 16);
  const blue = parseInt(hexColor.substring(5, 7), 16);

  // Calculate luminance
  const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

  // Determine contrast color (black or white)
  if (luminance > 0.5) {
    return '#000000'; // Dark color for light backgrounds
  } else {
    return '#FFFFFF'; // Light color for dark backgrounds
  }
};
