import { useCallback, useMemo } from 'react';
import Cookies from 'cookies-js';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

export const ItemSource = {
  URL: 'URL',
  Cookies: 'Cookies',
  API: 'API',
  None: 'None',
} as const;

export type ItemSourceValue = (typeof ItemSource)[keyof typeof ItemSource];

const useGetUrlKeyValueStore = () => {
  const location = useLocation();
  return useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);
};

const getStoreItemKey = (prefix: string, name: string) => `${prefix}_${name}`;

type LocallyOverridableInitialItem<T> = {
  key: string;
  value: T;
};

export function useLocallyOverridableKeyValueStorage<T>({
  initialItems,
  valueDecoders,
  valueEncoders,
  storePrefix,
}: {
  initialItems: Array<LocallyOverridableInitialItem<T>>;
  valueDecoders: {
    [ItemSource.URL]: (value: string) => T;
    [ItemSource.Cookies]: (value: string) => T;
    [ItemSource.API]: (value: T) => T;
  };
  valueEncoders: {
    [ItemSource.URL]?: (value: T) => string;
    [ItemSource.Cookies]?: (value: T) => string;
  };
  storePrefix: string;
}) {
  const urlKeyValueStore = useGetUrlKeyValueStore();

  const getValue = useCallback(
    (key: string) => {
      const keyInStore = getStoreItemKey(storePrefix, key);

      const urlValue = urlKeyValueStore[keyInStore];
      if (typeof urlValue === 'string' && urlValue !== '') {
        return {
          value: valueDecoders[ItemSource.URL](urlValue),
          source: ItemSource.URL,
        };
      }

      const cookieValue = Cookies.get(keyInStore);
      if (cookieValue !== undefined && cookieValue !== '') {
        return {
          value: valueDecoders[ItemSource.URL](cookieValue),
          source: ItemSource.Cookies,
        };
      }

      const initialItem = initialItems?.find(item => item.key === key);

      if (initialItem) {
        return {
          value: valueDecoders[ItemSource.API](initialItem.value),
          source: ItemSource.API,
        };
      }

      console.warn('Value not found', key);
      return {
        value: false as const,
        source: ItemSource.None,
      };
    },
    [initialItems, urlKeyValueStore, storePrefix, valueDecoders]
  );

  return {
    getValue,
    setValue: (key: string, value: T, source: 'URL' | 'Cookies') => {
      const encoder = valueEncoders[source];
      if (encoder) {
        Cookies.set(getStoreItemKey(storePrefix, key), encoder(value));
        return;
      }
      throw Error(`${source} not implemented`);
    },
    clearValue: (key: string, source: ItemSourceValue) => {
      if (source === ItemSource.Cookies) {
        Cookies.expire(getStoreItemKey(storePrefix, key));
        return;
      }
      throw Error(`${source} not implemented`);
    },
  };
}
