import { TransportInterface, BluetoothTransportOptions, TransportMessage } from './types';

export const ROBO_SERVICE_UUID = 'aaaaaaaa-77f1-415f-9c9e-8a22a7f02242';
export const ROBO_CHARACTERISTIC_TX_DATA = 'aa000000-77f1-415f-9c9e-8a22a7f02242';
export const ROBO_CHARACTERISTIC_TX_FLAG = 'aa000001-77f1-415f-9c9e-8a22a7f02242';
export const ROBO_CHARACTERISTIC_RX_DATA = 'aa000002-77f1-415f-9c9e-8a22a7f02242';
export const ROBO_CHARACTERISTIC_RX_FLAG = 'aa000003-77f1-415f-9c9e-8a22a7f02242';

/**
 * Implements Bluetooth transport functionality for Robo devices using Web Bluetooth API
 */
export class BluetoothTransport implements TransportInterface {
  private device: BluetoothDevice;
  private server: BluetoothRemoteGATTServer | null = null;
  private txDataCharacteristic: BluetoothRemoteGATTCharacteristic | null = null; // read/notify
  private txFlagCharacteristic: BluetoothRemoteGATTCharacteristic | null = null; // write
  private rxDataCharacteristic: BluetoothRemoteGATTCharacteristic | null = null; // write
  private rxFlagCharacteristic: BluetoothRemoteGATTCharacteristic | null = null; // read/notify
  private dataListener?: (message: TransportMessage) => void;
  private isConnected = false;
  private operationQueue: Promise<any> = Promise.resolve();

  /**
   * Creates a new BluetoothTransport instance
   * @param device - The Bluetooth device to connect to
   */
  constructor(device: BluetoothDevice) {
    this.device = device;
  }

  /**
   * Enqueues an operation to be executed sequentially to prevent concurrent Bluetooth operations
   * @param operation - Async operation to be executed
   * @returns Promise resolving to the operation result
   * @private
   */
  private enqueueOperation<T>(operation: () => Promise<T>): Promise<T> {
    // Chain the operations to ensure sequential execution without busy waiting
    this.operationQueue = this.operationQueue.then(() => operation());
    return this.operationQueue;
  }

  /**
   * Writes a value to a Bluetooth characteristic with operation queuing
   * @param characteristic - The characteristic to write to
   * @param value - The value to write
   * @private
   */
  private async writeCharacteristic(
    characteristic: BluetoothRemoteGATTCharacteristic,
    value: ArrayBuffer | Uint8Array
  ): Promise<void> {
    return this.enqueueOperation(async () => {
      await characteristic.writeValue(value);
    });
  }

  /**
   * Establishes connection to the Bluetooth device
   * @param options - Connection options
   * @throws Error if connection fails
   */
  async connect(options: BluetoothTransportOptions): Promise<void> {
    try {
      await this.ensureConnected();
    } catch (error) {
      console.error('Bluetooth connection failed:', error);
      this.isConnected = false;
      throw error;
    }
  }

  /**
   * Ensures GATT server connection and characteristic setup
   * @private
   * @throws Error if GATT server is not found or connection fails
   */
  private async ensureConnected(): Promise<void> {
    if (!this.device.gatt) {
      throw new Error('No GATT server found');
    }

    if (!this.server?.connected) {
      await this.enqueueOperation(async () => {
        this.server = await this.device.gatt!.connect();
        const service = await this.server.getPrimaryService(ROBO_SERVICE_UUID);

        // Get all required characteristics
        this.txDataCharacteristic = await service.getCharacteristic(ROBO_CHARACTERISTIC_TX_DATA);
        this.txFlagCharacteristic = await service.getCharacteristic(ROBO_CHARACTERISTIC_TX_FLAG);
        this.rxDataCharacteristic = await service.getCharacteristic(ROBO_CHARACTERISTIC_RX_DATA);
        this.rxFlagCharacteristic = await service.getCharacteristic(ROBO_CHARACTERISTIC_RX_FLAG);

        // Set up notifications for read/notify characteristics
        await this.txDataCharacteristic.startNotifications();
        await this.rxFlagCharacteristic.startNotifications();

        this.txDataCharacteristic.addEventListener(
          'characteristicvaluechanged',
          this.handleCharacteristicValueChanged.bind(this)
        );
        this.rxFlagCharacteristic.addEventListener(
          'characteristicvaluechanged',
          this.handleCharacteristicValueChanged.bind(this)
        );

        this.isConnected = true;
      });
    }
  }

  /**
   * Disconnects from the Bluetooth device and cleans up resources
   */
  async disconnect(): Promise<void> {
    await this.enqueueOperation(async () => {
      // Only try to stop notifications if we're still connected
      if (this.server?.connected) {
        if (this.txDataCharacteristic) {
          try {
            await this.txDataCharacteristic.stopNotifications();
          } catch (error) {
            console.warn('Error stopping TX notifications:', error);
          }
        }
        if (this.rxFlagCharacteristic) {
          try {
            await this.rxFlagCharacteristic.stopNotifications();
          } catch (error) {
            console.warn('Error stopping RX notifications:', error);
          }
        }
        this.server.disconnect();
      }

      // Clean up resources regardless of connection state
      this.server = null;
      this.txDataCharacteristic = null;
      this.txFlagCharacteristic = null;
      this.rxDataCharacteristic = null;
      this.rxFlagCharacteristic = null;
      this.isConnected = false;
    });
  }

  /**
   * Sends data to the connected Bluetooth device
   * @param data - Data to send, either as Uint8Array or string
   * @throws Error if device is not connected or send operation fails
   */
  async send(data: Uint8Array | string): Promise<void> {
    try {
      await this.ensureConnected();

      if (!this.rxDataCharacteristic || !this.txFlagCharacteristic) {
        throw new Error('Bluetooth not connected');
      }

      const buffer = typeof data === 'string' ? new TextEncoder().encode(data) : data;

      // Write data to RX data characteristic (write permission)
      await this.writeCharacteristic(this.rxDataCharacteristic, buffer);

      // Write to TX flag to signal data is ready (write permission)
      await this.writeCharacteristic(this.txFlagCharacteristic, new Uint8Array([1]));
    } catch (error) {
      console.error('Error sending data:', error);
      if (error instanceof DOMException && (error.name === 'NotSupportedError' || error.name === 'NetworkError')) {
        await this.reconnect();
        // Retry the send operation once after reconnecting
        if (!this.rxDataCharacteristic || !this.txFlagCharacteristic) {
          throw new Error('Bluetooth not connected after reconnect');
        }
        const buffer = typeof data === 'string' ? new TextEncoder().encode(data) : data;
        await this.writeCharacteristic(this.rxDataCharacteristic, buffer);
        await this.writeCharacteristic(this.txFlagCharacteristic, new Uint8Array([1]));
      } else {
        throw error;
      }
    }
  }

  /**
   * Attempts to reconnect to the device after connection loss
   * @private
   */
  private async reconnect(): Promise<void> {
    await this.disconnect();
    await this.connect({} as BluetoothTransportOptions);
  }

  /**
   * Sets up a listener for incoming data
   * @param listener - Callback function to handle received messages
   */
  onData(listener: (message: TransportMessage) => void): void {
    this.dataListener = listener;
  }

  /**
   * Handles characteristic value change events from the Bluetooth device
   * @param event - The characteristic value change event
   * @private
   */
  private handleCharacteristicValueChanged(event: Event): void {
    const characteristic = event.target as BluetoothRemoteGATTCharacteristic;
    const value = characteristic.value;

    if (!value || !this.dataListener) {
      return;
    }

    // Handle TX data characteristic (read/notify)
    if (characteristic.uuid === ROBO_CHARACTERISTIC_TX_DATA) {
      const data = new Uint8Array(value.buffer);
      this.dataListener({
        data,
      });
    }
    // Handle RX flag characteristic (read/notify)
    else if (characteristic.uuid === ROBO_CHARACTERISTIC_RX_FLAG && this.txFlagCharacteristic) {
      // When we receive RX flag notification, acknowledge by writing to TX flag
      this.writeCharacteristic(this.txFlagCharacteristic, new Uint8Array([1])).catch(error => {
        console.warn('Error acknowledging RX flag:', error);
      });
    }
  }

  /**
   * Waits for a specific message that matches the given predicate
   * @param predicate - Function to test incoming messages
   * @param timeout - Optional timeout in milliseconds
   * @returns Promise that resolves when a matching message is received
   * @throws Error if timeout occurs before receiving matching message
   */
  waitForSpecificMessage<T>(
    predicate: (message: TransportMessage, resolve: (value: T) => void) => void,
    timeout?: number
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const timeoutId = timeout
        ? setTimeout(() => {
            this.txDataCharacteristic?.removeEventListener('characteristicvaluechanged', handler);
            this.rxFlagCharacteristic?.removeEventListener('characteristicvaluechanged', handler);
            reject(new Error('Timeout'));
          }, timeout)
        : null;

      const handler = (event: Event) => {
        const characteristic = event.target as BluetoothRemoteGATTCharacteristic;
        const value = characteristic.value;
        if (!value) return;

        const message: TransportMessage = {
          data: new Uint8Array(value.buffer),
        };

        try {
          predicate(message, (result: T) => {
            if (timeoutId) clearTimeout(timeoutId);
            this.txDataCharacteristic?.removeEventListener('characteristicvaluechanged', handler);
            this.rxFlagCharacteristic?.removeEventListener('characteristicvaluechanged', handler);
            resolve(result);
          });
        } catch (error) {
          // If predicate throws, it means this wasn't the message we were waiting for
          // Don't reject the promise, just continue waiting
          return;
        }
      };

      this.txDataCharacteristic?.addEventListener('characteristicvaluechanged', handler);
      this.rxFlagCharacteristic?.addEventListener('characteristicvaluechanged', handler);
    });
  }
}
