import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import blockly from './assets/blockly.svg';
import code from './assets/code.svg';
import live from './assets/live.svg';
import main from './assets/main.svg';
import wunderkind from './assets/wunderkind.svg';
import webapp from './assets/webapp.svg';
import wunderkindWithText from './assets/wunderkind-with-text.svg';
import profile from './assets/profile.svg';

/**
 * Robowunderkind Logo
 */
export const Logo = ({ variant, width, height, alt, onClick = undefined, sx, ...rest }) => {
  const variants = {
    wunderkind,
    wunderkindWithText,
    live,
    blockly,
    code,
    main,
    profile,
    webapp,
  };

  width = parseInt(width, 10);
  height = parseInt(height, 10);

  const handleClick = () => {
    onClick ? onClick() : null;
  };

  return (
    <Box
      component="img"
      alt={alt}
      src={variants[variant]}
      onClick={handleClick}
      sx={{
        width: width + 'px',
        height: height + 'px',
        maxHeight: { xs: height / 2, md: height } + 'px',
        maxWidth: { xs: width / 2, md: width } + 'px',
        cursor: 'pointer',
        ...sx,
      }}
      {...rest}
    />
  );
};

Logo.propTypes = {
  /**
   * Logo variant
   */
  variant: PropTypes.oneOf([
    'wunderkind',
    'wunderkindWithText',
    'live',
    'blockly',
    'code',
    'main',
    'profile',
    'webapp',
  ]),

  width: PropTypes.string,

  height: PropTypes.string,

  /**
   * Alternative text
   */
  alt: PropTypes.string,

  /**
   * Optional click handler
   */
  onClick: PropTypes.func,

  sx: PropTypes.object,
};

Logo.defaultProps = {
  variant: 'wunderkind',
  onClick: undefined,
  alt: 'Robowunderkind Logo',
  width: 120,
  height: 120,
  sx: {},
};
