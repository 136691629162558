import { gql } from '@lib/store/strapi-graphql-query';
import { WITH_FILE } from '@store/fragments/file';

export const GLOBAL_QUERY = gql`
  ${WITH_FILE}
  query GetGlobalData {
    global {
      data {
        id
        attributes {
          siteName
          favicon {
            ...FILE
          }
          defaultSeo {
            metaTitle
            metaDescription
            shareImage {
              ...FILE
            }
          }
          steamHubNotification {
            id
            text
            level
            buttonText
            buttonUrl
            enabled
          }
        }
      }
    }
  }
`;
