/**
 * Compares two semantic version strings and determines their order.
 *
 * This function compares two semantic version strings (e.g., "1.0.0", "1.2.0") and returns -1, 0, or 1
 * to indicate whether the first version is less than, equal to, or greater than the second version,
 * respectively. The comparison is done part by part (major, minor, patch) and assumes missing parts
 * are equivalent to zero. This means "1.0" is equivalent to "1.0.0".
 *
 * @param {string} v1 - The first semantic version string to compare.
 * @param {string} v2 - The second semantic version string to compare.
 * @returns {-1 | 0 | 1} - Returns -1 if v1 < v2, 1 if v1 > v2, or 0 if they are equal.
 */
export const compareSemanticVersions = (v1: string, v2: string): -1 | 0 | 1 => {
  const v1Parts = v1.split('.').map(v => parseInt(v, 10));
  const v2Parts = v2.split('.').map(v => parseInt(v, 10));

  const maxLength = Math.max(v1Parts.length, v2Parts.length);
  for (let i = 0; i < maxLength; i++) {
    const v1Part = v1Parts[i] ?? 0;
    const v2Part = v2Parts[i] ?? 0;

    if (v1Part > v2Part) {
      return 1;
    } else if (v1Part < v2Part) {
      return -1;
    }
    // if parts are equal, continue to the next part
  }

  // If all parts are equal
  return 0;
};

/**
 * Parses a semantic version string into an object with major, minor, and build numbers.
 *
 * @param {string} version - The semantic version string to parse.
 * @returns {Object} - An object with major, minor, and build numbers.
 * @throws {Error} - If the version string is not in the correct format.
 */
export const parseSemanticVersion = (version: string) => {
  const parts = version.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid version format');
  }
  const [major, minor, build] = parts.map(v => parseInt(v, 10));
  return { major, minor, build };
};
