import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SemanticVersion } from '@store/types/common';
import { BridgeDeviceVersion, RoboDeviceVersion } from '@webapp/config/constants';

export type DeviceType = 'robo' | 'bridge';
export type RoboUpdateMethod = 'mqtt' | 'ota';
export type BridgeUpdateMethod = 'ota';
export type UpdateStatus = 'in_progress' | 'finished' | 'error' | 'stuck';
export type RenameStatus = 'in_progress' | 'finished' | 'error' | 'stuck';
export type AlternativeUpdateModalType = 'robo-wired' | 'robo-phone' | 'bridge-wired';

export type UpdateManagerState = {
  roboUpdateStatus: {
    id: string;
    deviceVersion: RoboDeviceVersion;
    status: UpdateStatus;
    updateMethod: RoboUpdateMethod;
    currentFirmware: SemanticVersion;
    nextFirmware: SemanticVersion;
  } | null;
  bridgeUpdateStatus: {
    id: string;
    deviceVersion: BridgeDeviceVersion;
    status: UpdateStatus;
    updateMethod: BridgeUpdateMethod;
    currentFirmware: SemanticVersion;
    nextFirmware: SemanticVersion;
  } | null;
  showRoboUpdateResultModal: boolean;
  showBridgeUpdateResultModal: boolean;
  showAlternativeUpdateModal: AlternativeUpdateModalType | null;
  availableFirmwares: {
    robo: {
      [key: string]: {
        lastVersion: SemanticVersion;
      };
    };
    bridge: {
      [key: string]: {
        lastVersion: SemanticVersion;
      };
    };
  };
  showRenameModal: {
    status: RenameStatus;
  } | null;
};

const initialState: UpdateManagerState = {
  roboUpdateStatus: null,
  bridgeUpdateStatus: null,
  showBridgeUpdateResultModal: false,
  showRoboUpdateResultModal: false,
  showAlternativeUpdateModal: null,
  availableFirmwares: {
    robo: {},
    bridge: {},
  },
  showRenameModal: null,
};

const updateManagerSlice = createSlice({
  name: 'updateManager',
  initialState,
  reducers: {
    setRoboUpdateStatus: (state, { payload }: PayloadAction<UpdateManagerState['roboUpdateStatus']>) => {
      state.roboUpdateStatus = payload;
    },
    updateRoboUpdateStatus: (state, { payload }: PayloadAction<Partial<UpdateManagerState['roboUpdateStatus']>>) => {
      if (state.roboUpdateStatus) {
        state.roboUpdateStatus = { ...state.roboUpdateStatus, ...payload };
      }
    },
    clearRoboUpdateStatus: state => {
      state.roboUpdateStatus = null;
    },
    setBridgeUpdateStatus: (state, { payload }: PayloadAction<UpdateManagerState['bridgeUpdateStatus']>) => {
      state.bridgeUpdateStatus = payload;
    },
    updateBridgeUpdateStatus: (
      state,
      { payload }: PayloadAction<Partial<UpdateManagerState['bridgeUpdateStatus']>>
    ) => {
      if (state.bridgeUpdateStatus) {
        state.bridgeUpdateStatus = { ...state.bridgeUpdateStatus, ...payload };
      }
    },
    clearBridgeUpdateStatus: state => {
      state.bridgeUpdateStatus = null;
    },
    setShowRoboUpdateResultModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showRoboUpdateResultModal = payload;
    },
    setShowBridgeUpdateResultModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showBridgeUpdateResultModal = payload;
    },
    setShowAlternativeUpdateModal: (state, { payload }: PayloadAction<AlternativeUpdateModalType | null>) => {
      state.showAlternativeUpdateModal = payload;
    },
    setShowRenameModal: (
      state,
      {
        payload,
      }: PayloadAction<{
        status: RenameStatus;
      } | null>
    ) => {
      if (payload) {
        state.showRenameModal = { status: payload.status };
      } else {
        state.showRenameModal = null;
      }
    },
    setAvailableFirmware: (
      state,
      {
        payload: { version, deviceType, deviceVersion },
      }: PayloadAction<{
        version: SemanticVersion;
        deviceType: DeviceType;
        deviceVersion: string;
      }>
    ) => {
      if (!state.availableFirmwares[deviceType][deviceVersion]) {
        state.availableFirmwares[deviceType][deviceVersion] = {
          lastVersion: version,
        };
      } else {
        state.availableFirmwares[deviceType][deviceVersion].lastVersion = version;
      }
    },
  },
});
export const {
  setRoboUpdateStatus,
  updateRoboUpdateStatus,
  clearRoboUpdateStatus,
  setBridgeUpdateStatus,
  updateBridgeUpdateStatus,
  clearBridgeUpdateStatus,
  setShowRoboUpdateResultModal,
  setShowBridgeUpdateResultModal,
  setShowAlternativeUpdateModal,
  setShowRenameModal,
  setAvailableFirmware,
} = updateManagerSlice.actions;

export default updateManagerSlice.reducer;
